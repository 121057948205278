import React, { memo } from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

let DealflowLogo = (props) => (
	<SvgIcon {...props} viewBox={"0 0 1234.73 281.74"}>
		<title>Dealflow</title>
		<g>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#41ACFF"
				d="M7.85,179.38v0.01c1.93,45.42,19.16,74.67,51.7,87.78v-117.3
		V91.61C27.02,104.72,9.79,133.97,7.85,179.38z"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#008FFE"
				d="M78.24,99.44V87.34c0-17.25,8.67-25.91,25.97-25.91h25.91v211.92
		c-8.07,1.26-16.84,1.86-26.3,1.79c-9.19-0.15-17.72-0.99-25.59-2.53V170.14V99.44z"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#007CDC"
				d="M148.81,44.59V32.5c0-17.25,8.66-25.91,25.97-25.91h25.91v176.24
		c0,45.41-17.3,74.08-51.89,85.98V94.35V44.59z"
			/>
			<g>
				<path
					fill="#222222"
					d="M354.39,222.73h-2c-3,5.17-7.67,9.59-14,13.25c-6.34,3.66-14.17,5.5-23.5,5.5
			c-7.84,0-15.29-1.62-22.38-4.88c-7.09-3.25-13.34-7.75-18.75-13.5c-5.42-5.75-9.71-12.62-12.88-20.62
			c-3.17-8-4.75-16.75-4.75-26.25s1.58-18.25,4.75-26.25c3.16-8,7.46-14.88,12.88-20.62c5.41-5.75,11.66-10.25,18.75-13.5
			c7.08-3.25,14.54-4.88,22.38-4.88c9.33,0,17.16,1.84,23.5,5.5c6.33,3.67,11,8.09,14,13.25h2l-2-17.5V58.48h32.75v179h-30.75
			V222.73z M321.64,211.23c4.33,0,8.46-0.79,12.38-2.38c3.91-1.58,7.38-3.88,10.38-6.88s5.41-6.66,7.25-11
			c1.83-4.33,2.75-9.25,2.75-14.75s-0.92-10.41-2.75-14.75c-1.84-4.33-4.25-8-7.25-11s-6.46-5.29-10.38-6.88
			c-3.92-1.58-8.04-2.38-12.38-2.38c-4.34,0-8.46,0.84-12.38,2.5c-3.92,1.67-7.38,4-10.38,7s-5.42,6.67-7.25,11
			c-1.84,4.34-2.75,9.17-2.75,14.5c0,5.34,0.91,10.17,2.75,14.5c1.83,4.34,4.25,8,7.25,11s6.46,5.34,10.38,7
			C313.18,210.4,317.3,211.23,321.64,211.23z"
				/>
				<path
					fill="#222222"
					d="M527.89,207.98c-5.67,10-13.29,18.09-22.88,24.25c-9.59,6.16-21.29,9.25-35.12,9.25
			c-9.34,0-17.96-1.62-25.88-4.88c-7.92-3.25-14.79-7.79-20.62-13.62c-5.84-5.83-10.38-12.71-13.62-20.62
			c-3.25-7.91-4.88-16.62-4.88-26.12c0-8.83,1.58-17.21,4.75-25.12c3.16-7.91,7.58-14.83,13.25-20.75
			c5.66-5.91,12.38-10.63,20.12-14.13s16.29-5.25,25.62-5.25c9.83,0,18.58,1.62,26.25,4.88c7.66,3.25,14.08,7.75,19.25,13.5
			c5.16,5.75,9.08,12.54,11.75,20.38c2.66,7.84,4,16.34,4,25.5c0,1.17,0,2.17,0,3c-0.17,1-0.25,1.92-0.25,2.75
			c-0.17,0.84-0.25,1.75-0.25,2.75h-92.25c0.66,5,2.04,9.29,4.12,12.88c2.08,3.59,4.66,6.59,7.75,9c3.08,2.42,6.46,4.17,10.12,5.25
			c3.66,1.09,7.41,1.62,11.25,1.62c7.5,0,13.71-1.71,18.62-5.12c4.91-3.41,8.79-7.71,11.62-12.88L527.89,207.98z M498.14,160.48
			c-0.17-2.16-0.88-4.58-2.12-7.25c-1.25-2.66-3.09-5.16-5.5-7.5c-2.42-2.33-5.42-4.25-9-5.75c-3.59-1.5-7.88-2.25-12.88-2.25
			c-7,0-13.17,2-18.5,6c-5.34,4-9.09,9.59-11.25,16.75H498.14z"
				/>
				<path
					fill="#222222"
					d="M627.64,224.48h-2c-3.84,4.67-8.46,8.67-13.88,12c-5.42,3.33-12.38,5-20.88,5
			c-6.34,0-12.29-1.04-17.88-3.12c-5.59-2.08-10.46-4.96-14.62-8.62c-4.17-3.66-7.42-8.08-9.75-13.25c-2.34-5.16-3.5-10.83-3.5-17
			c0-6.33,1.25-12.04,3.75-17.12c2.5-5.08,6-9.46,10.5-13.12c4.5-3.66,9.79-6.46,15.88-8.38c6.08-1.91,12.71-2.88,19.88-2.88
			c8,0,14.58,0.62,19.75,1.88c5.16,1.25,9.41,2.54,12.75,3.88v-3.75c0-6.5-2.59-11.88-7.75-16.12c-5.17-4.25-11.59-6.38-19.25-6.38
			c-11.34,0-20.92,4.75-28.75,14.25l-23.75-16.25c13-16.33,30.75-24.5,53.25-24.5c19,0,33.5,4.46,43.5,13.38
			c10,8.92,15,22.13,15,39.63v73.5h-32.25V224.48z M627.64,187.73c-3.84-1.83-7.79-3.29-11.88-4.38c-4.09-1.08-8.54-1.62-13.38-1.62
			c-7.84,0-13.88,1.71-18.12,5.12c-4.25,3.42-6.38,7.62-6.38,12.62s2.04,9,6.12,12c4.08,3,8.79,4.5,14.12,4.5
			c4.33,0,8.33-0.79,12-2.38c3.66-1.58,6.79-3.66,9.38-6.25c2.58-2.58,4.58-5.58,6-9C626.93,194.94,627.64,191.4,627.64,187.73z"
				/>
				<path fill="#222222" d="M685.88,237.48v-179h32.75v179H685.88z" />
				<path
					fill="#222222"
					d="M839.63,237.48V88.73h-29c-5.34,0-9.54,1.57-12.62,4.7c-3.09,3.13-4.62,7.67-4.62,13.61v7.95h28.75v28
			h-28.75v94.5h-32.75v-94.5h-22v-28h22v-7.61c0-15.89,4.25-28.01,12.75-36.36s20.83-12.53,37-12.53h62v179H839.63z"
				/>
				<path
					fill="#222222"
					d="M957.13,110.98c9.5,0,18.21,1.62,26.12,4.88c7.91,3.25,14.75,7.75,20.5,13.5
			c5.75,5.75,10.25,12.62,13.5,20.62s4.88,16.75,4.88,26.25s-1.62,18.25-4.88,26.25s-7.75,14.88-13.5,20.62s-12.59,10.25-20.5,13.5
			c-7.92,3.25-16.62,4.88-26.12,4.88s-18.21-1.62-26.12-4.88c-7.92-3.25-14.75-7.75-20.5-13.5s-10.25-12.62-13.5-20.62
			s-4.88-16.75-4.88-26.25s1.62-18.25,4.88-26.25s7.75-14.88,13.5-20.62c5.75-5.75,12.58-10.25,20.5-13.5
			C938.92,112.61,947.63,110.98,957.13,110.98z M957.13,211.23c4.16,0,8.21-0.79,12.12-2.38c3.91-1.58,7.38-3.88,10.38-6.88
			s5.38-6.66,7.12-11c1.75-4.33,2.62-9.25,2.62-14.75s-0.88-10.41-2.62-14.75c-1.75-4.33-4.12-8-7.12-11s-6.46-5.29-10.38-6.88
			c-3.92-1.58-7.96-2.38-12.12-2.38c-4.34,0-8.42,0.79-12.25,2.38c-3.84,1.59-7.25,3.88-10.25,6.88s-5.38,6.67-7.12,11
			c-1.75,4.34-2.62,9.25-2.62,14.75s0.88,10.42,2.62,14.75c1.75,4.34,4.12,8,7.12,11s6.41,5.29,10.25,6.88
			C948.71,210.44,952.8,211.23,957.13,211.23z"
				/>
				<path
					fill="#222222"
					d="M1027.63,114.98h36.75l20.25,76.75h1.75l24.25-76.75h33.75l24.5,76.75h1.75l20-76.75h36.25l-39.25,122.5
			h-35.25l-24.5-77.25h-1.75l-24.5,77.25h-34.75L1027.63,114.98z"
				/>
			</g>
		</g>
	</SvgIcon>
);

DealflowLogo = memo(DealflowLogo);
DealflowLogo.displayName = "DealflowLogo";
DealflowLogo.muiName = "DealflowLogo";

export default DealflowLogo;
